<template>
  <nav class="navbar min-w-1400">
    <div
      class="secondBox max-w-1400"
      :class="{
        'secondBox-borrom': false,
      }"
    >
      <div class="logo">
        <!-- <img
          :src="require('@/page/bienestarricotecnologia/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">EASY COMMU & SERVICES S.A.S.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Inicio</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Producto</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">ศูนย์ช่วยเหลือ</span>
          </router-link>
        </li> -->
        <li
          class="dropdown Li3"
          :class="{
            'active-link': dropdownPaths.includes($route.path),
          }"
        >
          <!-- 添加下拉图标 -->
          <router-link to="/introduce1" class="nav-link3">
            <span class="dropdown-toggle routeText3"
              >Soluciones por industria</span
            >
          </router-link>
          <ul class="dropdown-menu" ref="dropdownMenu">
            <li
              style="color: red"
              :class="{
                'active-link1': $route.path === '/introduce1',
              }"
            >
              <router-link to="/introduce1" class="dropdown-toggle routeText6">
                Industria automotriz
              </router-link>
            </li>
            <div class="dropdown-item-line"></div>
            <li
              :class="{
                'active-link1': $route.path === '/introduce2',
              }"
            >
              <router-link to="/introduce2" class="dropdown-toggle routeText7">
                Industria del personal
              </router-link>
            </li>
            <div class="dropdown-item-line"></div>
            <li
              :class="{
                'active-link1': $route.path === '/introduce3',
              }"
            >
              <router-link to="/introduce3" class="dropdown-toggle routeText8">
                Industria inmobiliaria
              </router-link>
            </li>
            <div class="dropdown-item-line"></div>
            <li
              :class="{
                'active-link1': $route.path === '/introduce4',
              }"
            >
              <router-link to="/introduce4" class="dropdown-toggle routeText9">
                Industria educativa
              </router-link>
            </li>
            <div class="dropdown-item-line"></div>
            <li
              :class="{
                'active-link1': $route.path === '/introduce5',
              }"
            >
              <router-link to="/introduce5" class="dropdown-toggle routeText10">
                Industria logística
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li4">
          <router-link to="/question" class="nav-link4">
            <span class="routeText4">Servicios y soporte</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
      dropdownPaths: [
        "/introduce1",
        "/introduce2",
        "/introduce3",
        "/introduce4",
        "/introduce5",
      ],
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #ffffff;
  /* box-shadow: 0px 3px 12.2px 0 rgba(65, 64, 64, 0.1); */
  /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  /* box-shadow: 0px 4px 9.4px 0px rgba(0, 0, 0, 0.1); */
}

.secondBox-borrom {
  /* box-shadow: 0px 1.5px 0px rgba(54, 53, 56, 1); */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #333333;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  height: 24px;
  line-height: 24px;
  /* height: 90px;
  line-height: 90px; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  /* font-weight: 400; */
  color: #A7A7A7;
  /* opacity: 0.8; */
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  height: 100%;
}

.nav-links li {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding: 6px;
  height: 39px;
  /* line-height: 90px; */
}

.Li1 {
  width: 100px;
}

.Li2 {
  width: 120px;
}

.Li3 {
  width: 230px;
}

.Li4 {
  width: 200px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.active-link {
  /* border-color: #fbc85b;
  background-color: #fbc85b; */
  border-radius: 26px;
  display: flex;
  align-items: center;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  font-weight: bold;
  color: #333333;
  /* opacity: 1; */
}

.title {
  /* border: 1px solid red; */
  /* padding-left: 30px; */
  width: 350px;
}

.secondBox {
  padding: 0 110px 0 150px;
}

.dropdown {
  position: relative;
}
.dropdown-toggle {
  cursor: pointer;
}
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 5px 0 20px;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 11.4px 0 rgba(0, 0, 0, 0.25);
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 16px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  height: 36px;
  line-height: 36px;
  /* font-weight: 550; */
  /* margin-top: 10px; */
}
.routeText6,
.routeText7,
.routeText8,
.routeText9,
.routeText10 {
  width: 100%;
  text-align: center;
  color: #666666;
}
.active-link1 .routeText6,
.active-link1 .routeText7,
.active-link1 .routeText8,
.active-link1 .routeText9,
.active-link1 .routeText10 {
  color: #000;
  background: #f8fafd;
  font-weight: 550;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
.dropdown-item-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 80%;
  margin-left: 10%;
}
</style>