<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-10-14 17:24:30
 * @LastEditors: cleardayyy 2270467108@qq.com
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="clickBox1" @click="gotoPage1"></div>
        <div class="clickBox2" @click="gotoPage2"></div>
        <div class="clickBox3" @click="gotoPage4"></div>
        <div class="clickBox4" @click="gotoLink"></div>
        <div class="clickBox5" @click="gotoPage3_1"></div>
        <div class="clickBox6" @click="gotoPage3_3"></div>
        <div class="clickBox7" @click="gotoPage3_5"></div>
        <div class="clickBox8" @click="gotoPage3_2"></div>
        <div class="clickBox9" @click="gotoPage3_4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/easycommuservicessas/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    gotoPage1() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        this.gotoTop();
      }
    },
    gotoPage2() {
      if (this.$route.path !== "/about") {
        this.$router.push("/about");
      } else {
        this.gotoTop();
      }
    },
    gotoPage4() {
      if (this.$route.path !== "/question") {
        this.$router.push("/question");
      } else {
        this.gotoTop();
      }
    },
    gotoLink() {
      // window.open("", '_blank');
    },
    gotoPage3_1() {
      if (this.$route.path !== "/introduce1") {
        this.$router.push("/introduce1");
      } else {
        this.gotoTop();
      }
    },
    gotoPage3_2() {
      if (this.$route.path !== "/introduce2") {
        this.$router.push("/introduce2");
      } else {
        this.gotoTop();
      }
    },
    gotoPage3_3() {
      if (this.$route.path !== "/introduce3") {
        this.$router.push("/introduce3");
      } else {
        this.gotoTop();
      }
    },
    gotoPage3_4() {
      if (this.$route.path !== "/introduce4") {
        this.$router.push("/introduce4");
      } else {
        this.gotoTop();
      }
    },
    gotoPage3_5() {
      if (this.$route.path !== "/introduce5") {
        this.$router.push("/introduce5");
      } else {
        this.gotoTop();
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}

.img1Box {
  position: relative;
}
.clickBox1 {
  position: absolute;
  background: transparent;
  width: 90px;
  height: 30px;
  top: 61%;
  left: 43.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox2 {
  position: absolute;
  background: transparent;
  width: 120px;
  height: 30px;
  top: 67%;
  left: 43.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox3 {
  position: absolute;
  background: transparent;
  width: 205px;
  height: 30px;
  top: 72.8%;
  left: 43.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox4 {
  position: absolute;
  background: transparent;
  width: 225px;
  height: 30px;
  top: 78.5%;
  left: 43.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox5 {
  position: absolute;
  background: transparent;
  width: 215px;
  height: 30px;
  top: 61.5%;
  left: 66%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox6 {
  position: absolute;
  background: transparent;
  width: 218px;
  height: 30px;
  top: 67.2%;
  left: 66%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox7 {
  position: absolute;
  background: transparent;
  width: 195px;
  height: 30px;
  top: 73%;
  left: 66%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox8 {
  position: absolute;
  background: transparent;
  width: 228px;
  height: 30px;
  top: 78.8%;
  left: 66%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox9 {
  position: absolute;
  background: transparent;
  width: 208px;
  height: 30px;
  top: 84.5%;
  left: 66%;
  cursor: pointer;
  /* border: 1px solid red; */
}
</style>
