import { render, staticRenderFns } from "./MyPage4.vue?vue&type=template&id=9bf00f32&scoped=true"
import script from "./MyPage4.vue?vue&type=script&lang=js"
export * from "./MyPage4.vue?vue&type=script&lang=js"
import style0 from "./MyPage4.vue?vue&type=style&index=0&id=9bf00f32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bf00f32",
  null
  
)

export default component.exports