<template>
  <div class="imgBox min-w-1400">
    <!-- <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div> -->
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox-item">
      <div>
        <div class="imgBox3 min-w-700" @click="toggleNewImage(3)">
          <div class="img3 max-w-700">
            <img
              :src="showNewImage3 ? AnswerImg3 : importedImg3"
              :alt="altText"
              class="imgItem3"
            />
          </div>
        </div>
        <div class="imgBox4 min-w-700" @click="toggleNewImage(4)">
          <div class="img4 max-w-700">
            <img
              :src="showNewImage4 ? AnswerImg4 : importedImg4"
              :alt="altText"
              class="imgItem4"
            />
          </div>
        </div>
        <div class="imgBox5 min-w-700" @click="toggleNewImage(5)">
          <div class="img5 max-w-700">
            <img
              :src="showNewImage5 ? AnswerImg5 : importedImg5"
              :alt="altText"
              class="imgItem5"
            />
          </div>
        </div>
        <div class="imgBox6 min-w-700" @click="toggleNewImage(6)">
          <div class="img6 max-w-700">
            <img
              :src="showNewImage6 ? AnswerImg6 : importedImg6"
              :alt="altText"
              class="imgItem6"
            />
          </div>
        </div>
        <div class="imgBox7 min-w-700" @click="toggleNewImage(7)">
          <div class="img7 max-w-700">
            <img
              :src="showNewImage7 ? AnswerImg7 : importedImg7"
              :alt="altText"
              class="imgItem7"
            />
          </div>
        </div>

        <div class="imgBox8 min-w-700" @click="toggleNewImage(8)">
          <div class="img8 max-w-700">
            <img
              :src="showNewImage8 ? AnswerImg8 : importedImg8"
              :alt="altText"
              class="imgItem8"
            />
          </div>
        </div>
        <div class="imgBox9 min-w-700" @click="toggleNewImage(9)">
          <div class="img9 max-w-700">
            <img
              :src="showNewImage9 ? AnswerImg9 : importedImg9"
              :alt="altText"
              class="imgItem9"
            />
          </div>
        </div>
        <div class="imgBox10 min-w-700" @click="toggleNewImage(10)">
          <div class="img10 max-w-700">
            <img
              :src="showNewImage10 ? AnswerImg10 : importedImg10"
              :alt="altText"
              class="imgItem10"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="imgBox11 min-w-700" @click="toggleNewImage(11)">
          <div class="img11 max-w-700">
            <img
              :src="showNewImage11 ? AnswerImg11 : importedImg11"
              :alt="altText"
              class="imgItem11"
            />
          </div>
        </div>
        <div class="imgBox12 min-w-700" @click="toggleNewImage(12)">
          <div class="img12 max-w-700">
            <img
              :src="showNewImage12 ? AnswerImg12 : importedImg12"
              :alt="altText"
              class="imgItem12"
            />
          </div>
        </div>
        <div class="imgBox13 min-w-700" @click="toggleNewImage(13)">
          <div class="img13 max-w-700">
            <img
              :src="showNewImage13 ? AnswerImg13 : importedImg13"
              :alt="altText"
              class="imgItem13"
            />
          </div>
        </div>

        <div class="imgBox14 min-w-700" @click="toggleNewImage(14)">
          <div class="img14 max-w-700">
            <img
              :src="showNewImage14 ? AnswerImg14 : importedImg14"
              :alt="altText"
              class="imgItem14"
            />
          </div>
        </div>
        <div class="imgBox15 min-w-700" @click="toggleNewImage(15)">
          <div class="img15 max-w-700">
            <img
              :src="showNewImage15 ? AnswerImg15 : importedImg15"
              :alt="altText"
              class="imgItem15"
            />
          </div>
        </div>
        <div class="imgBox16 min-w-700" @click="toggleNewImage(16)">
          <div class="img16 max-w-700">
            <img
              :src="showNewImage16 ? AnswerImg16 : importedImg16"
              :alt="altText"
              class="imgItem16"
            />
          </div>
        </div>
        <div class="imgBox17 min-w-700" @click="toggleNewImage(17)">
          <div class="img17 max-w-700">
            <img
              :src="showNewImage17 ? AnswerImg17 : importedImg17"
              :alt="altText"
              class="imgItem17"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="imgBox20 min-w-1400" @click="toggleNewImage(20)">
      <div class="img20 max-w-1400">
        <img :src="importedImg20" alt="" class="imgItem20" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";

import importedImg2 from "@/page/easycommuservicessas/components/img/4_1.png";
import importedImg3 from "@/page/easycommuservicessas/components/img/4_3.png";
import importedImg4 from "@/page/easycommuservicessas/components/img/4_4.png";
import importedImg5 from "@/page/easycommuservicessas/components/img/4_5.png";
import importedImg6 from "@/page/easycommuservicessas/components/img/4_6.png";
import importedImg7 from "@/page/easycommuservicessas/components/img/4_7.png";
import importedImg8 from "@/page/easycommuservicessas/components/img/4_8.png";
import importedImg9 from "@/page/easycommuservicessas/components/img/4_9.png";
import importedImg10 from "@/page/easycommuservicessas/components/img/4_10.png";
import importedImg11 from "@/page/easycommuservicessas/components/img/4_11.png";
import importedImg12 from "@/page/easycommuservicessas/components/img/4_12.png";
import importedImg13 from "@/page/easycommuservicessas/components/img/4_13.png";
import importedImg14 from "@/page/easycommuservicessas/components/img/4_14.png";
import importedImg15 from "@/page/easycommuservicessas/components/img/4_15.png";
import importedImg16 from "@/page/easycommuservicessas/components/img/4_16.png";
import importedImg17 from "@/page/easycommuservicessas/components/img/4_17.png";
import importedImg20 from "@/page/easycommuservicessas/components/img/4_20.png";

import AnswerImg3 from "@/page/easycommuservicessas/components/img/4_3_3.png";
import AnswerImg4 from "@/page/easycommuservicessas/components/img/4_4_4.png";
import AnswerImg5 from "@/page/easycommuservicessas/components/img/4_5_5.png";
import AnswerImg6 from "@/page/easycommuservicessas/components/img/4_6_6.png";
import AnswerImg7 from "@/page/easycommuservicessas/components/img/4_7_7.png";
import AnswerImg8 from "@/page/easycommuservicessas/components/img/4_8_8.png";
import AnswerImg9 from "@/page/easycommuservicessas/components/img/4_9_9.png";
import AnswerImg10 from "@/page/easycommuservicessas/components/img/4_10_10.png";
import AnswerImg11 from "@/page/easycommuservicessas/components/img/4_11_11.png";
import AnswerImg12 from "@/page/easycommuservicessas/components/img/4_12_12.png";
import AnswerImg13 from "@/page/easycommuservicessas/components/img/4_13_13.png";
import AnswerImg14 from "@/page/easycommuservicessas/components/img/4_14_14.png";
import AnswerImg15 from "@/page/easycommuservicessas/components/img/4_15_15.png";
import AnswerImg16 from "@/page/easycommuservicessas/components/img/4_16_16.png";
import AnswerImg17 from "@/page/easycommuservicessas/components/img/4_17_17.png";
export default {
  data() {
    return {
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      importedImg13: importedImg13,
      importedImg14: importedImg14,
      importedImg15: importedImg15,
      importedImg16: importedImg16,
      importedImg17: importedImg17,
      importedImg20: importedImg20,
      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,
      AnswerImg11: AnswerImg11,
      AnswerImg12: AnswerImg12,
      AnswerImg13: AnswerImg13,
      AnswerImg14: AnswerImg14,
      AnswerImg15: AnswerImg15,
      AnswerImg16: AnswerImg16,
      AnswerImg17: AnswerImg17,

      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
      showNewImage15: false,
      showNewImage16: false,
      showNewImage17: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
      if (imageIndex == 15) {
        this.showNewImage15 = !this.showNewImage15;
      }
      if (imageIndex == 16) {
        this.showNewImage16 = !this.showNewImage16;
      }
      if (imageIndex == 17) {
        this.showNewImage17 = !this.showNewImage17;
      }
      if (imageIndex == 20) {
        this.showNewImage20 = !this.showNewImage20;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  background: linear-gradient(#eef5ff 0%, #ffffff 28%);
}

img {
  display: block;
}

.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11,
.imgBox12,
.imgBox13,
.imgBox14,
.imgBox15,
.imgBox16,
.imgBox17,
.imgBox20 {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #FFFFFF; */
}

img {
  display: block;
}
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14,
.img15,
.img16,
.img17,
.img20 {
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  margin: 0;
}

.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14,
.imgItem15,
.imgItem16,
.imgItem17,
.imgItem20 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14,
.img15,
.img16,
.img17 {
  margin-top: 30px;
  transform: scale(92%);
  background-color: #f6faf7;
  border-radius: 16px;
}
.img2 {
  padding: 0 180px;
  margin-top: 80px;
}

.imgBox-item {
  display: flex;
  justify-content: center;
  margin: 30px 0 160px;
}
.img20 {
  padding: 0 230px;
  margin-bottom: 160px;
}
.min-w-700 {
  /* min-width: 660px; */
  min-width: 550px;
}
.max-w-700 {
  /* max-width: 660px; */
  max-width: 550px;
}
</style>