import { render, staticRenderFns } from "./MyPage1.vue?vue&type=template&id=37cd6866&scoped=true"
import script from "./MyPage1.vue?vue&type=script&lang=js"
export * from "./MyPage1.vue?vue&type=script&lang=js"
import style0 from "./MyPage1.vue?vue&type=style&index=0&id=37cd6866&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37cd6866",
  null
  
)

export default component.exports